require ("bootstrap");
import Vue from "vue";
import AOS from "aos";
import "aos/dist/aos.css";

// Cookies
require("./cookies");

window.Vue = Vue;
window.axios = require('axios');
AOS.init();

Vue.component('Index',  require('./Pages/Index').default);

const app = new Vue({
    el: '#app',
});
