// Cookies
import * as Klaro from "klaro";
const config = {
	htmlTexts: true,
	noticeAsModal: false,
	hideDeclineAll: true,
	mustConsent: false,
	default: true,
	lang: 'sl',
	services: [
		{
			name: 'obvezni',
			purposes: ['necessary'],
			default: true,
			required: true,
		},
		{
			name: 'analytics',
			title: 'Google Analytics',
			purposes: ['analytics'],
			cookies: [/^ga/i],
			default: true,
			optOut: true,
		}
	],
	translations: {

		sl: {
			ok: 'Sprejmi vse',
			close: 'Zapri',
			acceptSelected: 'Shrani',
			consentModal: {
				title: 'Uporaba piškotkov',
				description:
					'Na našem spletnem mestu uporabljamo piškotke za zagotavljanje najboljše uporabniške izkušnje. Več o naši <a href="https://www.vzajemci.com/piskotki" target="_blank" rel="noopener">rabi piškotkov</a>.',
			},
			purposeItem: {
				service: 'Piškotek',
				services: 'Piškotki',
			},
			consentNotice: {
				learnMore: 'Prilagodi',
				description:
					'Na našem spletnem mestu uporabljamo naslednje piškotke za zagotavljanje najboljše uporabniške izkušnje.',
			},
			purposes: {
				analytics: {
					title: 'Analitični piškotki',
					description:
						'Analitični piškotki nam pomagajo izboljšati našo spletno stran z zbiranjem in poročanjem podatkov o njeni uporabi.',
				},
				necessary: {
					title: 'Nujni piškotki',
					description:
						'Potrebni piškotki zagotavljajo bistveno funkcionalnost. Spletna stran brez piškotkov ne more pravilno delovati in jih lahko izključite le s spreminjanjem nastavitev brskalnika.',
				},
			},
			poweredBy: 'Poganja Klaro!',
			service: {
				required: {
					title: '(Vedno prisotni)'
				},
				optOut: {
					title: ' '
				},
				purpose: ' '
			}
		},
	},

};
window.klaro = Klaro;
window.klaroConfig = config;
Klaro.setup(config);
