var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", [
    _vm._m(0),
    _vm._v(" "),
    _c("section", { staticClass: "hero" }, [
      _c("div", { staticClass: "container mt-lg-4 mt-2 mt-sm-2" }, [
        _c("div", { staticClass: "row end-of-universe" }, [
          _c(
            "div",
            {
              staticClass:
                "col-12 col-xl-6 order-xl-1 col-lg-12 order-lg-1 col-md-12 order-md-1 order-1 pt-6"
            },
            [
              !_vm.submitted
                ? _c("div", [
                    _c("h1", { staticClass: "pl-6" }, [
                      _vm._v("Želim finančno svetovanje")
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "pl-6" }, [
                      _vm._v(
                        "Vzajemci Skupina smo vodilno finančnosvetovalno podjetje v regiji. V 20 letih\n\t\t\t\t\t\t\tdelovanja nam je zaupalo več kot 20 tisoč vlagateljev."
                      )
                    ])
                  ])
                : _c("div", [
                    _c("h1", { staticClass: "pl-6" }, [
                      _vm._v("Hvala vam za zaupanje in prijavo na svetovanje.")
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "pl-6" }, [
                      _vm._v(
                        "Osebni finančni svetovalec se vam bo javil v kratkem."
                      )
                    ])
                  ])
            ]
          ),
          _vm._v(" "),
          !_vm.submitted
            ? _c(
                "div",
                {
                  staticClass:
                    "col-12 col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 order-md-2 order-2 mt-sm-2 mt-xl-0"
                },
                [_vm._m(1)]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        !_vm.submitted
          ? _c("div", { staticClass: "row go-up" }, [
              _c(
                "div",
                {
                  staticClass: "col-12 col-xl-7 col-lg-12 col-md-12 col-sm-12"
                },
                [
                  _c("ConsultingForm", {
                    on: { formSubmitted: _vm.formSubmittedDone }
                  })
                ],
                1
              )
            ])
          : _c("div", [
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.consultants, function(consultant) {
                  return _c(
                    "div",
                    {
                      key: consultant.id,
                      staticClass: "col-md-3 d-flex flex-column"
                    },
                    [
                      _c("img", {
                        staticClass: "img-fluid",
                        attrs: {
                          src:
                            "https://consult.vzajemci.com/storage/" +
                            consultant.image,
                          alt: consultant.name
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "card flex-grow-1" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("h5", {
                            staticClass: "card-title",
                            domProps: { textContent: _vm._s(consultant.name) }
                          }),
                          _vm._v(" "),
                          _c("p", {
                            staticClass: "card-text",
                            domProps: {
                              textContent: _vm._s(consultant.job_title)
                            }
                          })
                        ])
                      ])
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm._m(2)
            ])
      ])
    ]),
    _vm._v(" "),
    !_vm.submitted ? _c("div", [_c("hr"), _vm._v(" "), _vm._m(3)]) : _vm._e(),
    _vm._v(" "),
    _vm._m(4)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container pt-xl-3 pb-xl-2" }, [
      _c("nav", { staticClass: "navbar navbar-light bg-light" }, [
        _c(
          "a",
          {
            staticClass: "navbar-brand",
            attrs: { href: "/", title: "Osebno finančno svetovanje" }
          },
          [
            _c("img", {
              attrs: {
                src: "images/skupina_logo.svg",
                width: "260",
                height: "72",
                alt: "Logotip Vzajemci",
                loading: "lazy"
              }
            })
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("picture", [
      _c("source", {
        attrs: {
          media: "(max-width: 480px)",
          srcset: "images/hero_img_mobile.png"
        }
      }),
      _vm._v(" "),
      _c("source", {
        attrs: {
          media: "(max-width: 1199.98px)",
          srcset: "images/hero_horizontal.png"
        }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "img-fluid",
        attrs: { src: "images/hero.png", alt: "Svetovanje" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("p", { staticClass: "small" }, [
          _vm._v("V vmesnem času vas vabimo, da nas spremljate na "),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.facebook.com/Vzajemci",
                title: "Facebook",
                target: "_blank",
                rel: "noopener nofollow"
              }
            },
            [_vm._v("FB")]
          ),
          _vm._v("/"),
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://www.linkedin.com/company/vzajemci-skupina-d.o.o./",
                title: "LinkedIn",
                target: "_blank",
                rel: "noopener nofollow"
              }
            },
            [_vm._v("linkedIn")]
          ),
          _vm._v(", ali pa si ogledate našo "),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.vzajemci.com",
                title: "VZAJEMCI SKUPINA d.o.o.",
                target: "_blank",
                rel: "noopener nofollow"
              }
            },
            [_vm._v("spletno mesto")]
          ),
          _vm._v(". ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-lg-5" }, [
          _c("h4", { staticClass: "dark" }, [
            _vm._v("Z našimi storitvami do urejenih osebnih financ:")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-3" }, [
          _c("h5", [_vm._v("Osebno finančno svetovanje")]),
          _vm._v(" "),
          _c("p", { staticClass: "small" }, [
            _vm._v("Naši strokovnjaki vam pomagajo optimizirati vaše finance.")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-3" }, [
          _c("h5", [_vm._v("Varčevanje")]),
          _vm._v(" "),
          _c("p", { staticClass: "small" }, [
            _vm._v("Kako varčevati in kakšni so vaši cilji.")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-3" }, [
          _c("h5", [_vm._v("Investicije")]),
          _vm._v(" "),
          _c("p", { staticClass: "small" }, [
            _vm._v("Naj vaš denar dela za vas.")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-3" }, [
          _c("h5", [_vm._v("Zavarovanje")]),
          _vm._v(" "),
          _c("p", { staticClass: "small" }, [
            _vm._v("Pregled in optimizacija zavarovanj.")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("div", { staticClass: "container" }, [
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-lg-4" }, [
            _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.vzajemci.com/",
                    title: "VZAJEMCI SKUPINA d.o.o.",
                    target: "_blank",
                    rel: "noopener"
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: "images/skupina_logo.svg",
                      width: "202",
                      height: "56",
                      alt: "Logotip Vzajemci - bik",
                      loading: "lazy"
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("small", [
              _vm._v("VZAJEMCI SKUPINA d.o.o."),
              _c("br"),
              _vm._v("Litostrojska 52"),
              _c("br"),
              _vm._v("1000 Ljubljana")
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-xl-4 offset-xl-4 col-lg-6 offset-lg-2 col-md-12 offset-md-0 col-sm-12 offset-sm-0 col-xs-12 offset-xs-0 text-xl-end text-lg-end text-md-start text-sm-start"
            },
            [
              _c("p", { staticClass: "pt-3" }, [
                _c(
                  "a",
                  {
                    staticClass: "social-icon",
                    attrs: {
                      href:
                        "https://www.linkedin.com/company/vzajemci-skupina-d.o.o./",
                      target: "_blank",
                      rel: "noopener nofollow"
                    }
                  },
                  [_c("i", { staticClass: "fab fa-linkedin-in" })]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "social-icon",
                    attrs: {
                      href: "https://www.facebook.com/Vzajemci",
                      target: "_blank",
                      rel: "noopener nofollow"
                    }
                  },
                  [_c("i", { staticClass: "fab fa-facebook" })]
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-3 mt-xl-5" }, [
          _c(
            "div",
            {
              staticClass:
                "col-12 order-2 col-xl-6 order-xl-1 col-lg-6 order-lg-2"
            },
            [
              _c("small", [
                _vm._v("Copyright © 2021 "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.vzajemci.com/",
                      title: "VZAJEMCI SKUPINA d.o.o.",
                      target: "_blank",
                      rel: "noopener"
                    }
                  },
                  [_vm._v("VZAJEMCI SKUPINA d.o.o.")]
                ),
                _vm._v(" Vse pravice\n\t\t\t\t\t\tpridržane.")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 order-1 col-xl-6 order-xl-2 col-lg-12 order-lg-1 text-xl-end text-lg-start text-md-start text-sm-start"
            },
            [
              _c("small", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.vzajemci.com/pravno-obvestilo",
                      title: "Pravno obvestilo",
                      target: "_blank",
                      rel: "noopener"
                    }
                  },
                  [_vm._v("Pravno obvestilo")]
                )
              ])
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }