<template>
	<main>
		<!-- Header -->

		<div class="container pt-xl-3 pb-xl-2">
			<nav class="navbar navbar-light bg-light">
				<a class="navbar-brand" href="/" title="Osebno finančno svetovanje">
					<img src="images/skupina_logo.svg" width="260" height="72" alt="Logotip Vzajemci"
						 loading="lazy">
				</a>
			</nav>
		</div>

		<!-- Hero -->

		<section class="hero">

			<div class="container mt-lg-4 mt-2 mt-sm-2">

				<div class="row end-of-universe">

					<div class="col-12 col-xl-6 order-xl-1 col-lg-12 order-lg-1 col-md-12 order-md-1 order-1 pt-6">
						<div v-if="!submitted">
							<h1 class="pl-6">Želim finančno svetovanje</h1>
							<p class="pl-6">Vzajemci Skupina smo vodilno finančnosvetovalno podjetje v regiji. V 20 letih
								delovanja nam je zaupalo več kot 20 tisoč vlagateljev.</p>
						</div>
						<div v-else>
							<h1 class="pl-6">Hvala vam za zaupanje in prijavo na svetovanje.</h1>
							<p class="pl-6">Osebni finančni svetovalec se vam bo javil v kratkem.</p>
						</div>
					</div>

					<div v-if="!submitted"
						class="col-12 col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 order-md-2 order-2 mt-sm-2 mt-xl-0">
						<picture>
							<source media="(max-width: 480px)" srcset="images/hero_img_mobile.png">
							<source media="(max-width: 1199.98px)" srcset="images/hero_horizontal.png">
							<img src="images/hero.png" alt="Svetovanje" class="img-fluid">
						</picture>
					</div>

				</div>

				<div class="row go-up" v-if="!submitted">

					<!-- Form -->
					<div class="col-12 col-xl-7 col-lg-12 col-md-12 col-sm-12">

						<ConsultingForm @formSubmitted="formSubmittedDone" />

					</div>

				</div>

				<div v-else>
					<div class="row">

						<!-- Cards -->
						<div class="col-md-3 d-flex flex-column" v-for="consultant in consultants" :key="consultant.id">

							<img :src="`https://consult.vzajemci.com/storage/${consultant.image}`" class="img-fluid" :alt="consultant.name">
							<div class="card flex-grow-1">
								<div class="card-body">
									<h5 class="card-title" v-text="consultant.name"></h5>
									<p class="card-text" v-text="consultant.job_title"></p>
								</div>
							</div>

						</div>

					</div>

					<div class="row mt-4">
						<div class="col-12">
							<p class="small">V vmesnem času vas vabimo, da nas spremljate na <a href="https://www.facebook.com/Vzajemci" title="Facebook" target="_blank" rel="noopener nofollow">FB</a>/<a href="https://www.linkedin.com/company/vzajemci-skupina-d.o.o./" title="LinkedIn" target="_blank" rel="noopener nofollow">linkedIn</a>, ali pa si ogledate našo <a href="https://www.vzajemci.com" title="VZAJEMCI SKUPINA d.o.o." target="_blank" rel="noopener nofollow">spletno mesto</a>. </p>
						</div>
					</div>

				</div>

			</div>

		</section>

		<div v-if="!submitted">
			<hr>

			<div class="container">

				<div class="row">

					<div class="col-12 col-lg-5">
						<h4 class="dark">Z našimi storitvami do urejenih osebnih financ:</h4>
					</div>

				</div>

				<div class="row">

					<div class="col-md-3">
						<h5>Osebno finančno svetovanje</h5>
						<p class="small">Naši strokovnjaki vam pomagajo optimizirati vaše finance.</p>
					</div>

					<div class="col-md-3">
						<h5>Varčevanje</h5>
						<p class="small">Kako varčevati in kakšni so vaši cilji.</p>
					</div>

					<div class="col-md-3">
						<h5>Investicije</h5>
						<p class="small">Naj vaš denar dela za vas.</p>
					</div>

					<div class="col-md-3">
						<h5>Zavarovanje</h5>
						<p class="small">Pregled in optimizacija zavarovanj.</p>
					</div>

				</div>


			</div>
		</div>

		<!-- Footer -->

		<footer>

			<div class="container">

				<hr>

				<div class="row">

					<div class="col-12 col-lg-4">
						<p>
							<a href="https://www.vzajemci.com/" title="VZAJEMCI SKUPINA d.o.o." target="_blank" rel="noopener"><img src="images/skupina_logo.svg" width="202" height="56"
													   alt="Logotip Vzajemci - bik" loading="lazy"></a>
						</p>
						<small>VZAJEMCI SKUPINA d.o.o.<br>Litostrojska 52<br>1000 Ljubljana</small>
					</div>

					<div
						class="col-xl-4 offset-xl-4 col-lg-6 offset-lg-2 col-md-12 offset-md-0 col-sm-12 offset-sm-0 col-xs-12 offset-xs-0 text-xl-end text-lg-end text-md-start text-sm-start">
						<p class="pt-3">
							<a href="https://www.linkedin.com/company/vzajemci-skupina-d.o.o./" class="social-icon" target="_blank" rel="noopener nofollow"><i
								class="fab fa-linkedin-in"></i></a>
							<a href="https://www.facebook.com/Vzajemci" class="social-icon" target="_blank" rel="noopener nofollow"><i
								class="fab fa-facebook"></i></a>
						</p>
					</div>

				</div>

				<hr>

				<div class="row mt-3 mt-xl-5">

					<div class="col-12 order-2 col-xl-6 order-xl-1 col-lg-6 order-lg-2">
						<small>Copyright &copy; 2021 <a href="https://www.vzajemci.com/" title="VZAJEMCI SKUPINA d.o.o." target="_blank" rel="noopener">VZAJEMCI SKUPINA d.o.o.</a> Vse pravice
							pridržane.</small>
					</div>

					<div
						class="col-12 order-1 col-xl-6 order-xl-2 col-lg-12 order-lg-1 text-xl-end text-lg-start text-md-start text-sm-start">
						<small><a href="https://www.vzajemci.com/pravno-obvestilo" title="Pravno obvestilo" target="_blank" rel="noopener">Pravno obvestilo</a></small>
					</div>

				</div>


			</div>

		</footer>
	</main>
</template>

<script>
import ConsultingForm from '@/Components/ConsultingForm';

export default {
	name: 'Index',
	data() {
		return {
			submitted: false,
			consultants: null
		}
	},
	components: {
		ConsultingForm
	},
	methods: {
		formSubmittedDone(consultants) {
			this.submitted = true;
			this.consultants = consultants.data;
		}
	}
}
</script>
