var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    !_vm.submitted
      ? _c(
          "div",
          {
            attrs: {
              "data-aos": "fade-up",
              "data-aos-duration": "320",
              "data-aos-easing": "linear"
            }
          },
          [
            _c("ValidationObserver", {
              ref: "observer",
              attrs: { tag: "form" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submitForm.apply(null, arguments)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var invalid = ref.invalid
                      return [
                        _c("ValidationProvider", {
                          attrs: { rules: "required" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("label", { attrs: { for: "name" } }, [
                                        _vm._v("Ime in priimek")
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: _vm.form.name,
                                            expression: "form.name",
                                            modifiers: { trim: true }
                                          }
                                        ],
                                        staticClass: "form-control",
                                        class: { "is-invalid": errors.length },
                                        attrs: { type: "text", id: "name" },
                                        domProps: { value: _vm.form.name },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.form,
                                              "name",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      errors.length
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t" +
                                                  _vm._s(errors[0]) +
                                                  "\n\t\t\t\t\t"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          attrs: { rules: "required|email" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("label", { attrs: { for: "email" } }, [
                                        _vm._v("Vaš e-mail naslov")
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: _vm.form.email,
                                            expression: "form.email",
                                            modifiers: { trim: true }
                                          }
                                        ],
                                        staticClass: "form-control",
                                        class: { "is-invalid": errors.length },
                                        attrs: { type: "email", id: "email" },
                                        domProps: { value: _vm.form.email },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.form,
                                              "email",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      errors.length
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t" +
                                                  _vm._s(errors[0]) +
                                                  "\n\t\t\t\t\t"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          attrs: { rules: "required" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("label", { attrs: { for: "phone" } }, [
                                        _vm._v("Telefonska številka")
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: _vm.form.phone,
                                            expression: "form.phone",
                                            modifiers: { trim: true }
                                          }
                                        ],
                                        staticClass: "form-control",
                                        class: { "is-invalid": errors.length },
                                        attrs: { type: "number", id: "phone" },
                                        domProps: { value: _vm.form.phone },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.form,
                                              "phone",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      errors.length
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t" +
                                                  _vm._s(errors[0]) +
                                                  "\n\t\t\t\t\t"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group form-check mb-4" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.terms,
                                  expression: "form.terms"
                                }
                              ],
                              staticClass: "form-check-input",
                              attrs: { type: "checkbox", id: "terms" },
                              domProps: {
                                checked: Array.isArray(_vm.form.terms)
                                  ? _vm._i(_vm.form.terms, null) > -1
                                  : _vm.form.terms
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.form.terms,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.form,
                                          "terms",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.form,
                                          "terms",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.form, "terms", $$c)
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "form-check-label",
                                attrs: { for: "terms" }
                              },
                              [
                                _vm._v("Strinjam se s "),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "https://www.vzajemci.com/politika-zasebnosti",
                                      target: "_blank",
                                      rel: "noopener",
                                      title: "Pogoji uporabe"
                                    }
                                  },
                                  [_vm._v("pogoji\n\t\t\t\t\tuporabe")]
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary btn-lg",
                            attrs: {
                              type: "submit",
                              disabled: _vm.submit_disabled
                            }
                          },
                          [_vm._v("Naročite se na svetovanje")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                857113226
              )
            })
          ],
          1
        )
      : _c("div")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }