<template>
	<section>
		<div v-if="!submitted" data-aos="fade-up" data-aos-duration="320" data-aos-easing="linear">
			<ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="submitForm">
				<ValidationProvider rules="required" v-slot="{ errors }">
					<div class="form-group">
						<label for="name">Ime in priimek</label>
						<input type="text" class="form-control" id="name" v-model.trim="form.name" :class="{ 'is-invalid': errors.length}">
						<div v-if="errors.length" class="invalid-feedback">
							{{ errors[0] }}
						</div>
					</div>
				</ValidationProvider>

				<ValidationProvider rules="required|email" v-slot="{ errors }">
					<div class="form-group">
						<label for="email">Vaš e-mail naslov</label>
						<input type="email" class="form-control" id="email" v-model.trim="form.email" :class="{ 'is-invalid': errors.length}">
						<div v-if="errors.length" class="invalid-feedback">
							{{ errors[0] }}
						</div>
					</div>
				</ValidationProvider>

				<ValidationProvider rules="required" v-slot="{ errors }">
					<div class="form-group">
						<label for="phone">Telefonska številka</label>
						<input type="number" class="form-control" id="phone" v-model.trim="form.phone" :class="{ 'is-invalid': errors.length}">
						<div v-if="errors.length" class="invalid-feedback">
							{{ errors[0] }}
						</div>
					</div>
				</ValidationProvider>

				<div class="form-group form-check mb-4">
					<input type="checkbox" class="form-check-input" id="terms" v-model="form.terms">
					<label class="form-check-label" for="terms">Strinjam se s <a href="https://www.vzajemci.com/politika-zasebnosti" target="_blank" rel="noopener" title="Pogoji uporabe">pogoji
						uporabe</a></label>
				</div>
				<button type="submit" class="btn btn-primary btn-lg" :disabled="submit_disabled">Naročite se na svetovanje</button>
			</ValidationObserver>
		</div>
		<div v-else>

		</div>
	</section>
</template>

<script>
import {ValidationProvider, ValidationObserver, extend, localize} from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

extend('required', {
	...required, required
});

extend('email', {
	...email, email
});

localize({
	sl: {
		messages: {
			required: 'Polje je obvezno.',
			email: 'Nepravilen elektronski naslov.',
		}
	}
});

localize('sl');

export default {
	name: 'ConsultingForm',
	components: {
		ValidationProvider,
		ValidationObserver
	},
	data() {
		return {
			submitted: false,
			submit_disabled: false,
			form: {
				name: null,
				email: null,
				phone: null,
				terms: null,
				message: 'Povpraševanje za svetovanje'
			}
		}
	},
	methods: {
		 async submitForm() {
			this.submit_disabled = true;
			const isValid = await this.$refs.observer.validate();
			if (!isValid) {
				this.submit_disabled = false;
				return false;
			}

			axios.post('https://api.vzajemci.com/api/v1/contact', this.form)
				.then(response => {
					if (!response.error) {
						this.submitted = true;
						this.$emit('formSubmitted', response.data);
					}
				}).catch(function (error) {

				});
		}
	}
}

</script>
